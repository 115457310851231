import { globalEventHub } from "@/util/globalEvents";
import * as _logger from "@/util/logger";

async function SearchUsers(userStr, token) {
  try {
    const headers = new Headers();
    const bearer = `Bearer ${token}`;

    headers.append("Authorization", bearer);
    headers.append("ConsistencyLevel", "eventual");

    const options = {
      method: "GET",
      headers: headers,
    };

    const searchString = `https://graph.microsoft.com/v1.0/users?$search="displayName:${userStr}"`;
    const userResponse = await fetch(searchString, options).catch((error) => {
      _logger.logError(error, "graph.SearchUsers")
      throw error;
    });

    const result = await userResponse.json();

    return result;
  } catch (err) {
    globalEventHub.emitErrorEvent(
      "Unexpected error searching for users in graph", err, "graph"
    );
    return { value: [] };
  }
}

function buildFile(fileName, fileBlob) {
  return {
    "@odata.type": "#microsoft.graph.fileAttachment",
    name: fileName,
    contentBytes: fileBlob,
  }
}

async function SendMail(token, userId, subject, toList, ccList, body, attachmentList) {
  try {
    const headers = new Headers();
    const bearer = `Bearer ${token}`;
    headers.append("Authorization", bearer);
    headers.append("Content-Type", "application/json");
    headers.append("ConsistencyLevel", "eventual");

    const options = {
      method: "POST",
      headers: headers,
    };

    const payload = {
      message: {
        subject: subject,
        body: {
          contentType: "HTML",
          content: body,
        },
        toRecipients: toList.map(t => ({ emailAddress: { address: t.trim() } })),
        ccRecipients: ccList.map(t => ({ emailAddress: { address: t.trim() } })),
      },
      saveToSentItems: true,
    };
    if (attachmentList && attachmentList.length > 0) {
      payload.message.attachments = attachmentList.map(a => buildFile(a.fileName, a.data));
    }

    options.body = JSON.stringify(payload);

    const sendURl = `https://graph.microsoft.com/v1.0/users/${userId}/sendMail`;
    const mailResp = await fetch(sendURl, options);

    if (!mailResp.ok) {
      const mailRespJson = await mailResp.json();
      _logger.logError("Error sending email returned from Graph API", "graph.SendMail");
      _logger.logError(JSON.stringify(mailRespJson), "graph.SendMail")
      _logger.logError(JSON.stringify(mailResp), "graph.SendMail")
    }

    return mailResp.ok;
  }
  catch (err) {
    globalEventHub.emitErrorEvent(
      "Unexpected error sending email.", err, "graph"
    );
    return false;
  }
}

export { SearchUsers, SendMail };
