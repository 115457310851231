<template>
  <div>
    <v-snackbar v-model="isShown" :timeout="-1" vertical color="primary">
      <div class="text-h5 white--text">An update is available.</div>
      <div class="caption white--text">
        Please refresh to get the latest changes.
      </div>
      <template v-slot:action="{ attrs }">
        <v-btn text v-bind="attrs" @click="update">
          Refresh <v-icon class="ml-3">mdi-refresh</v-icon>
        </v-btn>
      </template>
    </v-snackbar>
  </div>
</template>

<script>
import { globalEventHub, globalEvents } from "@/util/globalEvents";
import { setUpToDate } from "@/util/serviceWorkerState";
import { logInformation } from "@/util/logger";

export default {
  name: "UpdateAvailableSnackbar",
  data: () => ({
    isShown: false,
  }),
  created: function () {
    globalEventHub.subscribeToGlobalEvent(
      globalEvents.handleUpdateAvailable,
      () => {
        this.isShown = true;
      }
    );
  },
  methods: {
    update() {
      setUpToDate();
      logInformation("Refreshing page after snackbar prompt", "UpdateAvailableSnackbar");
      window.location.reload();
    },
  },
};
</script>
