import Vue from "vue";

let cookiesInstance;

export class cookies {
  install(vue) {
    cookiesInstance = this;
    vue.prototype.$cookies = Vue.observable(cookiesInstance);
  }

  set(key, value) {
    document.cookie = `${key}=${value || ""};SameSite=None;Secure=true;path=/`;
  }

  clear(key) {
    document.cookie = `${key}=;path=/`;
  }
}
