function IsNullorWhitespace(string) {
  if (string === undefined) {
    return true;
  }
  if (string === null) {
    return true;
  }
  if (typeof string !== "string") {
    return true;
  }
  if (string.length == 0) {
    return true;
  }
  if (string.trim().length == 0) {
    return true;
  }

  return false;
}

export { IsNullorWhitespace };
