import { defineStore } from "pinia";
import * as userDA from "@/dataAccess/userDataAccess";
import * as lcache from "@/util/localCache";
import * as graph from "@/util/graph";
import { useLoginStore } from "@/store/loginStore";
import * as logger from "@/util/logger";

// This store serves as the list of all users, and a list of all consultant users. It's dumb right now, can be made less dumb in the future.
export const useUserListStore = defineStore("userListStore", {
  state: () => {
    const _allUsers = [];
    const _allConsultants = [];

    const _userStoreKey = "userStoreListKey";
    const _consultantStoreKey = "consultantStoreListKey";

    const _loginStore = useLoginStore();

    return {
      _allUsers,
      _allConsultants,
      _userStoreKey,
      _consultantStoreKey,
      _loginStore,
    };
  },
  getters: {
    allUsers: (state) => state._allUsers,
    allConsultants: (state) => state._allConsultants.map((c) => {
      const roles = c.userRoles?.filter(ur => ur.category === "Consultant" && ur.name != "Consultant Scheduler");
      const returnVal = roles.length != 0 ? roles.map(ur => ur.name).reduce((a, c) => `${a}, ${c}`) : "No Roles";
      return { ...c, role: roles ? returnVal : "No Roles" };
    }),
  },
  actions: {
    async loadConsultants() {
      const cacheValue = lcache.Get(this._consultantStoreKey);
      if (cacheValue) {
        this._allConsultants = cacheValue;
        return null;
      }

      const consultantResponse = await userDA.getAllConsultants();

      if (consultantResponse.errors) {
        return consultantResponse.errors;
      }

      this._allConsultants = consultantResponse.data;

      lcache.Add(this._consultantStoreKey, consultantResponse.data);
      return null;
    },
    async loadUsers() {
      const cacheValue = lcache.Get(this._userStoreKey);
      if (cacheValue) {
        this._allUsers = cacheValue;
        return null;
      }

      const userResponse = await userDA.getAll();

      if (userResponse.errors) {
        return userResponse.errors;
      }

      this._allUsers = userResponse.data;

      lcache.Add(this._userStoreKey, userResponse.data);
      return null;
    },
    async resetUserList() {
      lcache.Clear(this._userStoreKey);
      return await this.loadUsers();
    },
    clearConsultantList() {
      lcache.Clear(this._consultantStoreKey);
    },
    async getUsersForQuery(query) {
      try {
        const token = await this._loginStore.getGraphToken();
        const graphResp = await graph.SearchUsers(query, token);

        return graphResp.value;
      } catch (err) {
        logger.logError(err, "userListStore");
        return [];
      }
    },
  },
});
