import apiWrapper from "./apiWrapper";

class facilityDataAccess {
  constructor() {
    this.apiWrapper = new apiWrapper();
  }

  async getAll() {
    return await this.apiWrapper.getAuthenticated("api/facility");
  }

  async getAllOptions() {
    return await this.apiWrapper.getAuthenticated(
      "api/facility/getfacilityoptions"
    );
  }

  async getById(id) {
    return await this.apiWrapper.getAuthenticated(`api/facility/${id}`);
  }

  async editFacility(facility) {
    return await this.apiWrapper.putAuthenticated("api/facility", facility);
  }

  async editFacilitySchedule(facility) {
    return await this.apiWrapper.putAuthenticated("api/facility/schedule", facility);
  }

  async getAllDispensePatterns() {
    return await this.apiWrapper.getAuthenticated(
      "api/facilitydispensepattern"
    );
  }

  async getAllRemoteDispense() {
    return await this.apiWrapper.getAuthenticated("api/facilityremotedispense");
  }
  async getAllEKitTypes() {
    return await this.apiWrapper.getAuthenticated("api/facilityekittype");
  }

  async getAllShippingSchedules() {
    return await this.apiWrapper.getAuthenticated("api/shippingschedule");
  }

  async getAllLocationTypes() {
    return await this.apiWrapper.getAuthenticated("api/facilitylocationtype");
  }

  async getAllRouteColors() {
    return await this.apiWrapper.getAuthenticated("api/routecolor");
  }

  async getFacilityTypes() {
    return await this.apiWrapper.getAuthenticated("api/facilitytype");
  }

  async addFacility(facility) {
    return await this.apiWrapper.postAuthenticated("api/facility", facility);
  }
  async getActiveFrameworkIds() {
    return await this.apiWrapper.getAuthenticated("api/facility/getactiveframeworkids");
  }
  async deleteFacility(id) {
    return await this.apiWrapper.deleteAuthenticated(`api/facility/${id}`);
  }
}

export default facilityDataAccess;
