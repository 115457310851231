import * as disp from "@/util/display";

export function formatDateTime(dateObj) {
  if (!dateObj) {
    return "";
  }

  return `${formatDate(dateObj)} ${dateObj.toLocaleTimeString("en-US")}`;
}

export function dateObjectToDateTimeString(date) {
  const dateString = new Intl.DateTimeFormat("en", {
    dateStyle: "short",
    timeStyle: "short",
  }).format(date);
  return dateString;
}

export function formatDateMMDDYY(date) {
  // YYYY-MM-DD --> MM/DD/YYYY
  const dateReg = /^\d{4}-\d{2}-\d{2}$/;
  if (!date || !date.match(dateReg)) {
    return null;
  }

  const [year, month, day] = date.split("-");
  return `${month}/${day}/${year}`;
}

export function parseDate(date) {
  // MM/DD/YYYY --> YYYY-MM-DD
  const dateReg = /^\d{2}\/\d{2}\/\d{4}$/;
  if (!date || !date.match(dateReg)) {
    return null;
  }

  const [month, day, year] = date.split("/");
  return `${year}-${month.padStart(2, "0")}-${day.padStart(2, "0")}`;
}

export function getFormattedCurrentDate() {
  var d = new Date(),
    month = "" + (d.getMonth() + 1),
    day = "" + d.getDate(),
    year = d.getFullYear();

  if (month.length < 2) month = "0" + month;
  if (day.length < 2) day = "0" + day;

  return [year, month, day].join("-");
}

export function formatDate(dateObj) {
  if (!dateObj) {
    return "";
  }

  let month = "" + (dateObj.getMonth() + 1);
  let day = "" + dateObj.getDate();
  let year = dateObj.getFullYear();

  if (month.length < 2) month = "0" + month;
  if (day.length < 2) day = "0" + day;

  return [month, day, year].join("/");
}

export function timeToAMPM(time) {
  if (disp.IsNullorWhitespace(time)) {
    return "";
  }

  var ts = time.split(":");
  if (ts.length != 2) {
    return "";
  }

  let ret = "";
  var hh = parseInt(ts[0]);
  if (hh > 12) {
    ret = `${hh - 12}:${ts[1]} PM`;
  } else if (hh == 12) {
    ret = `${time} PM`;
  } else if (hh == 0) {
    ret = `12:${ts[1]} AM`;
  } else {
    ret = `${time} AM`;
  }

  if (ret[0] == "0") {
    ret = ret.substring(1, ret.length);
  }
  return ret;
}

// Expects 0 = January
export function MonthNumToName(monthNum) {
  const monthNames = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"];
  return monthNames[monthNum];
}