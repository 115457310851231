import Vue from "vue";
import * as logger from "@/util/logger";

let eventHub = new Vue();

export class globalEventHub {
  static subscribeToGlobalEvent(eventName, callback) {
    eventHub.$on(eventName, (e) => {
      callback(e);
    });
  }

  static emitGlobalEvent(eventName, eventData) {
    eventHub.$emit(eventName, eventData);
  }

  static emitErrorEvent(userMessage, errorData, objectName) {
    if(errorData && objectName) {
      logger.logError(errorData, objectName);
    }
    // If the error message is the same as the error data, don't log it twice
    // also there are places that don't pass anything other than the user message
    // and there are no errors/messages to log for example: "Please wait for files to finish loading."
    if((userMessage !== errorData) && (userMessage && objectName)){
      logger.logError(userMessage, objectName);
    }

    this.emitGlobalEvent(globalEvents.handleError, userMessage);
  }

  static emitWarningEvent(message) {
    this.emitGlobalEvent(globalEvents.handleWarning, message);
  }

  static emitSuccessEvent(successData) {
    this.emitGlobalEvent(globalEvents.handleSuccess, successData);
  }
}

export class globalEvents {
  static handleError = "handleError";
  static handleWarning = "handleWarning";
  static handleSuccess = "handleSuccess";
  static handleUpdateAvailable = "handleUpdateAvailable";
}
