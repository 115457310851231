const defaultTimeout = 300000; // 1000 * 60 * 5 -> 5 minutes

function Get(key) {
  // TS: null | object
  const ostr = localStorage[key];
  if (!ostr) {
    return null;
  }

  const obj = JSON.parse(ostr);
  const now = new Date();
  if (now.getTime() > obj.expiry) {
    localStorage.removeItem(key);
    return null;
  }
  return obj.value;
}

function Add(key, object) {
  // TS: undefined
  const now = new Date();
  const item = { value: object, expiry: now.getTime() + defaultTimeout };
  localStorage.setItem(key, JSON.stringify(item));
}

function GetNoExpiry(key) {
  // TS: null | object
  const ostr = localStorage[key];
  if (!ostr) {
    return null;
  }

  const obj = JSON.parse(ostr);
  return obj.value;
}
function AddNoExpiry(key, object) {
  const item = { value: object };
  localStorage.setItem(key, JSON.stringify(item));
}

function Clear(key) {
  localStorage.removeItem(key);
}

export { Get, Add, Clear, GetNoExpiry, AddNoExpiry };
