export default {
  car: "CAR",
  task: "Task",
  defect: "Defect",
  psa: "PSA",
  serviceEvent: "Service Event",
  parentCar: "Parent-CAR",
  parentTask: "Parent-Task",
  adSiteVisitSummary: "AD Site Visit Summary",
  leadershipUpdate: "Rehab Leadership Update",
  businessServiceReview: "Rehab Business Service Review",
};
