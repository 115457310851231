<template>
  <div>
    <div v-if="!canEdit">
      {{ displayTime }}
    </div>
    <div v-else>
      <v-menu
        ref="menu"
        v-model="menu"
        :close-on-content-click="false"
        :nudge-right="40"
        transition="scale-transition"
        offset-y
        max-width="290px"
        min-width="290px"
        :disabled="!canEdit"
      >
        <template v-slot:activator="{ on, attrs }">
          <v-text-field
            v-model="displayTime"
            :label="label"
            :prepend-icon="iconValue"
            readonly
            v-bind="attrs"
            v-on="on"
            clearable
            @click:clear="callClear()"
            :disabled="!canEdit"
            :hide-details="hideDetailsValue"
          ></v-text-field>
        </template>
        <v-time-picker
          v-if="menu"
          v-model="time"
          full-width
          :allowed-minutes="allowedStep"
          ampm-in-title
        ></v-time-picker>
      </v-menu>
    </div>
  </div>
</template>

<script>
import * as dt from "@/util/dateFormats";

export default {
  name: "TimeSelect",
  components: {},
  props: {
    canEdit: {
      type: Boolean,
      default: false,
    },
    value: {
      type: String,
    },
    label: {
      type: String,
      default: "",
    },
    step: {
      type: Number,
      default: 1,
    },
    hideDetails: {
      type: Boolean,
      default: false,
    },
    hideIcon: {
      type: Boolean,
      default: false,
    },
  },
  data: () => ({
    menu: false,
  }),
  computed: {
    time: {
      get: function () {
        return this.value;
      },
      set: function (newValue) {
        this.$emit("input", newValue);
      },
    },
    displayTime: {
      get: function () {
        return dt.timeToAMPM(this.time);
      },
      set: function () {},
    },
    hideDetailsValue() {
      return this.hideDetails ? "auto" : false;
    },
    iconValue() {
      return this.hideIcon ? "" : "mdi-clock-time-four-outline";
    },
  },
  mounted() {},
  methods: {
    allowedStep(v) {
      return v % this.step == 0;
    },
    callClear() {
      this.time = "";
    },
  },
};
</script>
