
// This in-memory utility class handles state for the last route visited by the user, for use by the internal back button. 
// Note the default to the 'home' route.
// Also includes a flag for sharing state between the router and the internal back button.
var lastPath = ["/"];
var internal_Back = false;

function addLastPath(path) {
    lastPath.push(path);
}

function getLastPathAndPop() {
    const path = lastPath.pop();
    return path ?? '/'
}

function setInternalBack()
{
    internal_Back = true;
}

function getClearInternalBack()
{
    const ret = internal_Back;
    internal_Back = false;
    return ret;
}

export { addLastPath, getLastPathAndPop, setInternalBack, getClearInternalBack };