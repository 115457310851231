import Vue from "vue"

// sets all the values of the input object to the target object using Vue.set
function safeSet(inputObject, targetObject) {
    Object.entries(targetObject).forEach(
        (i) => Vue.delete(targetObject, i[0])
    );
    if (inputObject !== undefined && inputObject !== null && (Object.keys(inputObject)?.length ?? 0) > 0) {
        Object.entries(inputObject).forEach(
            (i) => Vue.set(targetObject, i[0], i[1]));
    }
}

export { safeSet }