<template>
  <div>
    <v-row no-gutters class="mt-3">
      <v-col cols="12">
        <span class="section-title">{{ title }}</span>
      </v-col>
    </v-row>
    <v-divider class="section-divider mb-4"></v-divider>
  </div>
</template>

<script>
export default {
  name: "FieldSectionTitle",
  components: {},
  props: {
    title: {
      type: String,
      default: "",
    },
  },
  data: () => ({}),
  computed: {},
  watch: {},
};
// todo: consider moving style to central location
</script>

<style scoped>
.section-title {
  font-weight: 400;
  font-size: 1.175rem !important;
  line-height: 1.75rem;
  letter-spacing: 0.0125rem !important;
  font-family: "Roboto", sans-serif !important;
}

.section-divider {
  border-top-width: 1.5px;
}
</style>
