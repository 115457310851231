<template>
  <div>
    <span v-if="!isEditing">{{ internalValue }}</span>
    <v-textarea
      v-if="isEditing"
      :disabled="!isEditing"
      :readonly="!isEditing"
      no-resize
      :dense="isEditing"
      outlined
      v-model="internalValue"
      :counter="counter"
      :rules="rules"
      :label="label"
      auto-grow
      :rows="rows"
    ></v-textarea>
  </div>
</template>

<script>
export default {
  name: "FieldTextArea",
  components: {},
  props: {
    isEditing: {
      type: Boolean,
      default: false,
    },
    value: {
      type: null,
      default: "",
    },
    counter: {
      type: [Number, Boolean, String],
      default: 1000,
    },
    rules: {
      type: Array,
      default: () => [],
    },
    label: {
      type: String,
      default: "",
    },
    rows: {
      type: Number,
      default: 4,
    }
  },
  data: () => ({
    internalValue: "",
  }),
  computed: {},
  watch: {
    internalValue: {
      handler() {
        this.$emit("input", this.internalValue);
      },
    },
    value: {
      immediate: true,
      handler() {
        this.internalValue = this.value;
      },
    },
    isEditing: {
      handler() {
        this.internalValue = this.value;
      },
    },
  },
};
</script>
