<template>
  <v-card elevation="5" class="mt-3">
    <v-card-title class="text-h5">Page Not Found</v-card-title>
    <v-card-text>
      <p>
        Sorry, the page you are looking for is not available.
      </p>
    </v-card-text>
  </v-card>
</template>

<script>

export default {
  name: "NotFound",
};
</script>