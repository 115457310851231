import "@mdi/font/css/materialdesignicons.css";
import Vue from "vue";
import Vuetify from "vuetify/lib";

import "@fontsource/roboto/100.css";
import "@fontsource/roboto/300.css";
import "@fontsource/roboto/400.css";
import "@fontsource/roboto/500.css";
import "@fontsource/roboto/700.css";
import "@fontsource/roboto/900.css";

Vue.use(Vuetify);

export default new Vuetify({
  theme: {
    options: { customProperties: true },
    themes: {
      light: {
        primary: "#38929B",
        primaryDarker: "#307B82",
        blue: "#56A0D3",
        consonusBlue: "#3B6E8F",
        consonusBlue10: "#EFF6FB",
        secondary: "#D9531E",
        lightSecondary: "#FBEEE9",
        background: "#F2F2F2",
        burntOrange: "#E08D26",
        lightBurntOrange: "#FFFAE6",
        accent: "#FFCF01",
        orange: "#F99D2B",
        black: "#000000",
        lightGrey: "#E4E5E6",
        lightGrey20: "#FAFAFA",
        darkGrey: "#717073",
        lightGreen: "#C1D82F",
        redTextAccessible: "D23A2F",
      },
    },
  },
  icons: {
    iconfont: "mdi",
  },
});
