<template>
  <v-app-bar app clipped-left flat color="white" class="raised-appbar">
    <v-app-bar-nav-icon @click.stop="toggleDrawer()"></v-app-bar-nav-icon>
    <router-link to="/" class="text-decoration-none black--text">
      <v-row class="iconFormat">
        <img src="@/assets/consonus_logo.svg" width="35" height="35" class="mx-4" alt="Consonus pharmacy logo" />
        <h2 class="text-h5 font-weight-medium mt-1">ConnectMe</h2>
      </v-row>
    </router-link>
    <div class="text-no-wrap envMessageDisplay"><span class="text-h5 font-weight-medium mt-1">&emsp; {{ envMessage }}</span>
    </div>
    <v-spacer></v-spacer>
    <v-menu offset-y>
      <template v-slot:activator="{ on, attrs }">
        <v-btn text v-bind="attrs" v-on="on" class="text-none">
          <span class="font-weight-medium text-body-1 mr-4">
            {{ fullName }}
          </span>
          <v-icon class="mr-4" color="black" size="20">mdi-chevron-down</v-icon>
        </v-btn>
      </template>
      <v-list>
        <v-list-item v-for="(item, index) in items" :key="index" link @click="handleSelection(item)">
          <v-list-item-title>{{ item.title }}</v-list-item-title>
        </v-list-item>
      </v-list>
    </v-menu>
  </v-app-bar>
</template>

<script>
import { useLoginStore } from "@/store/loginStore";
import { useUserStore } from "@/store/userStore";
import * as stateManager from "@/util/stateManagement";
import { useSettingsStore } from "@/store/settingsStore";

export default {
  name: "AppBar",
  props: {
    drawer: {
      type: Boolean,
      default: false,
    },
  },
  data: () => ({
    loggedInUserId: 0,
  }),
  computed: {
    items() {
      return [{ title: "Sign Out", url: "/signout" }, { title: "Reset All Filters", url: "/" }, { title: "My Profile", url: "/user/" + this.loggedInUserId }];
    },
    fullName() {
      const ls = useLoginStore();
      return ls.user.fullName;
    },
    userEmail() {
      const ls = useLoginStore();
      return ls.user.username;
    },
    envMessage() {
      const settingStore = useSettingsStore();
      return settingStore.environmentMessage;
    }
  },
  methods: {
    async handleSelection(item) {
      if (item.title === "Reset All Filters") {
        stateManager.clearAllFilters(this.userEmail);
        this.$router.go();
      }
      else {
        this.$router.push(item.url);
      }
    },
    toggleDrawer() {
      this.$emit("update:drawer", !this.drawer);
    },
  },
  mounted: async function () {
    const settingsStore = useSettingsStore();
    await settingsStore.loadSettings();

    const us = useUserStore();
    await us.loadLoggedInUser();
    this.loggedInUserId = us.loggedInUser.id;
  },
};
</script>

<style lang="scss" scoped>
.envMessageDisplay {
  color: red;
  overflow: hidden;
  text-overflow: ellipsis;
}

.cursor-pointer {
  cursor: pointer;
}

.v-sheet.v-toolbar {
  border-bottom: 1px solid var(--v-lightGrey-base) !important;
}

.iconFormat {
  flex-wrap: nowrap
}
</style>
