<template>
  <v-app>
    <div v-if="showSplash" class="h-100">
      <splash-page :errorText="errorText"></splash-page>
    </div>
    <div v-else>
      <NavDrawer v-if="!isInitializing && !errorText" :drawer.sync="drawer" />
      <AppBar v-if="!isInitializing && !errorText" :style="{ background: $vuetify.theme.themes[theme].background }"
        :drawer.sync="drawer" />
      <v-main class="h-100vh" :style="{ background: $vuetify.theme.themes[theme].background }">
        <router-view :key="$route.path" v-if="!isInitializing && !errorText" />
        <Loading v-if="isInitializing && !errorText" />
        <Error :errorText="errorText" v-if="errorText" />
      </v-main>
    </div>
    <UpdateAvailableSnackbar />
    <GlobalErrorSnackbar />
    <GlobalSuccessSnackbar />
  </v-app>
</template>

<script>
import Vue from "vue";
import router from "./router";
import AppBar from "@/components/AppBar";
import NavDrawer from "@/components/NavDrawer.vue";
import Loading from "@/components/Loading.vue";
import Error from "@/components/Error.vue";
import VueAppInsights from "vue-application-insights";
import { ApplicationInsights } from '@microsoft/applicationinsights-web'
import GlobalErrorSnackbar from "@/components/GlobalErrorSnackbar.vue";
import GlobalSuccessSnackbar from "@/components/GlobalSuccessSnackbar.vue";
import UpdateAvailableSnackbar from "@/components/UpdateAvailableSnackbar.vue";
import { useLoginStore } from "@/store/loginStore";
import { useSettingsStore } from "@/store/settingsStore";
import SplashPage from "@/views/SplashPage.vue";
import * as logger from "@/util/logger"

export default {
  name: "App",
  components: {
    AppBar,
    NavDrawer,
    Error,
    Loading,
    GlobalErrorSnackbar,
    GlobalSuccessSnackbar,
    UpdateAvailableSnackbar,
    SplashPage,
  },
  data: () => ({
    showSplash: true,
    isInitializing: true,
    errorText: "",
    defaultErrorText: "An unknown error ocurred",
    defaultUnauthorizedText:
      "You are not authorized to use this application. Check with the Administrator.",
    drawer: true,
  }),
  computed: {
    theme() {
      return this.$vuetify.theme.dark ? "dark" : "light";
    },
  },
  methods: {
    initAppInsights(key) {
      try {
        if (key) {
          Vue.use(VueAppInsights, {
            id: key,
            router,
          });

          let appInsights = new ApplicationInsights({
            config: {
              instrumentationKey: key
            }
          });

          appInsights.loadAppInsights();
          appInsights.trackPageView();

          logger.setAppInsights(appInsights);
        }
      }
      catch (ex) {
        console.log(ex);
      }
    }
  },
  mounted: async function () {
    try {
      this.drawer = !this.$vuetify.breakpoint.mobile;

      const settingsStore = useSettingsStore();

      const settingsResp = await settingsStore.loadSettings();
      if (settingsResp) {
        this.errorText = settingsResp[0]?.message ?? this.defaultErrorText;
        return;
      }
      //need the logger to be initialized before using the loginStore
      this.initAppInsights(settingsStore.appInsightsKey);

      const loginStore = useLoginStore();

      await loginStore.init(
        settingsStore.clientId,
        `https://login.microsoftonline.com/${settingsStore.tenantId}`,
        window.location.origin
      );

      if (!loginStore.isAuthenticated) {
        await loginStore.login();
        return;
      } else {
        if (!loginStore.isAuthorized) {
          this.errorText =
            "You are not authorized to use this application. Please contact an administrator.";
          logger.logError(this.errorText, "App.vue");
          return;
        }
      }

      // Make an authed call and ensure we are logged in, get roles for the user.
      const roleResp = await loginStore.loadUserRoles();
      if (roleResp) {
        this.errorText =
          roleResp.title ??
          "Unable to load your roles. Please sign out (below) and sign in again.";
          logger.logError(this.errorText, "App.vue");
        return;
      }


      this.showSplash = false;
    } finally {
      this.isInitializing = false;
    }
  },
};
</script>
