<template>
  <div>
    <v-autocomplete
      v-if="editing && canEdit"
      :placeholder="label"
      :label="label"
      append-icon
      :search-input.sync="search"
      prepend-inner-icon="mdi-magnify"
      v-model="facility"
      :items="facilityList"
      item-text="displayName"
      item-value="id"
      outlined
      clearable
      dense
      multiple
      chips
      deletable-chips
      :rules="rules"
      @change="clearSearch"
    >
    </v-autocomplete>
  </div>
</template>

<script>
import { useFacilityListStore } from "@/store/facilityListStore";
import { formatFacilityNameForSearch } from "@/logic/FacilityLogic";

export default {
  name: "FacilityMultiSelect",
  components: {},
  props: {
    canEdit: {
      type: Boolean,
      default: true,
    },
    editing: {
      type: Boolean,
      default: false,
    },
    value: {
      type: Array,
      default: () => [],
    },
    label: {
      type: String,
      default: "Facility",
    },
    rules: {
      type: Array,
      default: () => [],
    },
  },
  data: () => ({
    search: null,
  }),
  computed: {
    facilityList() {
      const facilityListStore = useFacilityListStore();
      let returnList = facilityListStore.allFacilityOptions ?? [];
      return formatFacilityNameForSearch(returnList);
    },
    facility: {
      get: function () {
        return this.value;
      },
      set: function (newValue) {
        this.$emit("input", newValue);
      },
    },
  },
  methods: {
    clearSearch() {
      this.search = null;
    },
  },
};
</script>
