import { defineStore } from "pinia";
import { safeSet } from "@/util/setter.js";
import * as userDA from "@/dataAccess/userDataAccess";
import { useLoginStore } from "@/store/loginStore";

// This store contains data about a user object, not the logged in user.
export const useUserStore = defineStore("userStore", {
  state: () => {
    const _currentUser = {};
    const _loggedInUser = {};
    return {
      _currentUser,
      _loggedInUser,
    };
  },
  getters: {
    user: (state) => state._currentUser,
    loggedInUser: (state) => state._loggedInUser,
  },
  actions: {
    async loadUser(userId) {
      const resp = await userDA.getById(userId);
      if (resp.errors) {
        return resp.errors;
      }

      safeSet(resp.data, this._currentUser);

      return null;
    },
    async loadUserByGraphId(graphId) {
      const resp = await userDA.getByGraphId(graphId);
      if (resp.errors) {
        return resp.errors;
      }

      safeSet(resp.data, this._currentUser);

      return null;
    },
    async loadLoggedInUser() {
      let ls = useLoginStore();
      const resp = await userDA.getByGraphId(ls.user.accountId);
      if (resp.errors) {
        return resp.errors;
      }

      safeSet(resp.data, this._loggedInUser);

      return null;
    },
  },
});
