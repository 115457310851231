<template>
  <v-autocomplete
    v-bind="$attrs"
    v-on="$listeners"
    outlined
    :search-input.sync="searchInput"
    @change="searchInput = ''"
  />
</template>

<script>
// Custom version of autocomplete that blanks out the typed search text when a
// selection is made. This allows the use of the keyboard + enter to choose
// multiple items without requiring manual backspacing of what was already
// typed. This is the desired behavior throughout the app.

// Also centralizes the setting of props that are always common to the control

export default {
  name: "AppAutocomplete",
  data: () => ({
    searchInput: null,
  }),
};
</script>
