<template>
  <div>
    <v-snackbar
      v-for="error in errors"
      :key="error.index"
      v-model="errors[error.index].isShown"
      :timeout="10000"
      :multi-line="true"
      color="error"
    >
      <span class="black--text font-weight-medium" bold>{{
        error.message
      }}</span>
      <template v-slot:action="{ attrs }">
        <v-btn
          color="black"
          text
          v-bind="attrs"
          @click="errors[error.index].isShown = false"
        >
          Close
        </v-btn>
      </template>
    </v-snackbar>
  </div>
</template>

<script>
import { globalEventHub, globalEvents } from "@/util/globalEvents";

export default {
  name: "GlobalErrorSnackbar",
  data: () => ({
    errors: [],
  }),
  created: function () {
    globalEventHub.subscribeToGlobalEvent(
      globalEvents.handleError,
      (message) => {
        if (Array.isArray(message)) {
          message = message.map((error) => error.message).join(" // ");
        }
        this.showErrorMessage(message);
      }
    );
  },
  methods: {
    showErrorMessage(errorMessage) {
      this.errors.push({
        message: errorMessage,
        index: this.errors.length,
        isShown: true,
      });
    },
  },
};
</script>
