import { useUserListStore } from "@/store/userListStore";

async function QueryGraph(val) {
      if (!val || val.trim().length == 0) {
        return null;
      }

      const userListStore = useUserListStore();
      const results = await userListStore.getUsersForQuery(val);

      let users = results.map((i) => {
        let name = `${i.givenName ?? ""} ${i.surname ?? ""}`.trim();
        if (name.length == 0) {
          name = i.displayName;
        }
        return {
          id: 0,
          graphId: i.id,
          fullName: name,
          firstName: i.givenName,
          lastName: i.surname,
          emailAddress: i.mail,
          jobTitle: i.jobTitle,
          AADOrigin: true,
        };
      });
      users = users.filter(
        (u) => u.emailAddress && u.emailAddress.length > 0
      );

    return users;
}
  

export { QueryGraph }