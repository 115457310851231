import { register } from "register-service-worker";
import { globalEventHub, globalEvents } from "@/util/globalEvents";
import { setNotUpToDate } from "@/util/serviceWorkerState";
import { logInformation } from "@/util/logger";

if (process.env.NODE_ENV === "production") {
  register(`/service-worker.js`, {
    updated() {
      setNotUpToDate();
      globalEventHub.emitGlobalEvent(globalEvents.handleUpdateAvailable);
      logInformation("New version available. User Prompted.", "registerServiceWorker.js");
    },
  });
}
