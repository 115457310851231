import apiWrapper from "./apiWrapper";

async function loadRoles() {
  const api = new apiWrapper();
  return await api.getAuthenticated("api/user/roles");
}

async function getAll() {
  const api = new apiWrapper();
  return await api.getAuthenticated("api/user");
}

async function getById(userId) {
  const api = new apiWrapper();
  return await api.getAuthenticated(`api/user/${userId}`);
}

async function getByGraphId(userGraphId) {
  const api = new apiWrapper();
  return await api.getAuthenticated(`api/user/graph/${userGraphId}`);
}

async function updateUserValues(userId, model) {
  const api = new apiWrapper();
  return await api.putAuthenticated(`api/user/${userId}`, model);
}

async function getAllConsultants() {
  const api = new apiWrapper();
  return await api.getAuthenticated("api/user/consultants");
}

export { loadRoles, getAll, getById, getByGraphId, updateUserValues, getAllConsultants };
