var upToDate = true;

function setNotUpToDate() {
    upToDate = false;
}

function setUpToDate() {
    upToDate = true;
}

function isUpToDate() {
    return upToDate;
}

export { setNotUpToDate, setUpToDate, isUpToDate }