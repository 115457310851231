<template>
  <div>
    <div v-if="!editing || !canEdit" :class="viewClassList">
      <router-link
        v-if="isAssigned"
        :to="{
          name: 'User',
          params: { id: `${id}` },
        }"
      >
        {{ name }}
      </router-link>
      <div v-else>{{ emptyText }}</div>
    </div>
    <v-autocomplete
      v-if="editing && canEdit"
      :placeholder="labelModified"
      prepend-inner-icon="mdi-magnify"
      v-model="internalValue"
      :search-input.sync="search"
      :loading="loading"
      :items="items"
      hide-no-data
      outlined
      dense
      item-text="fullName"
      item-value="graphId"
      :filter="filter"
      return-object
      clearable
      :rules="rules"
      :hide-details="hideDetails"
    >
      <template v-slot:item="data">
        <template>
          <v-list-item-content>
            <v-list-item-title>{{ data.item.fullName }}</v-list-item-title>
            <v-list-item-subtitle class="text--disabled">
              {{ data.item.emailAddress }}
            </v-list-item-subtitle>
          </v-list-item-content>
        </template>
      </template>
    </v-autocomplete>
  </div>
</template>

<script>
import _ from "lodash";
import { globalEventHub } from "@/util/globalEvents";
import { QueryGraph } from "@/logic/UserLookupLogic";

export default {
  name: "UserSelect",
  props: {
    canEdit: {
      type: Boolean,
      default: true,
    },
    editing: {
      type: Boolean,
      default: false,
    },
    viewClassList: {
      type: String,
      default: "",
    },
    value: {
      type: Object,
      default: () => {},
    },
    label: {
      type: String,
      default: "User",
    },
    rules: {
      type: Array,
      default: () => [],
    },
    allowUnassigned: {
      type: Boolean,
      default: true,
    },
    extraUsers: {
      type: Array,
      default: () => null,
    },
    filter: {
      type: Function,
      default: (item, queryText, itemText) => {
        if (itemText === "Unassigned") return true;
        var filtered =
          itemText.toLocaleLowerCase().indexOf(queryText.toLocaleLowerCase()) >
          -1;
        return filtered;
      },
    },
    emptyText: {
      type: String,
      default: "Unassigned",
    },
    hideDetails: {
      type: [Boolean, String],
      default: false,
    },
  },
  data: () => ({
    internalValue: {},
    search: null,
    internalUsers: [],
    loading: false,
    items: [],
  }),
  computed: {
    isAssigned() {
      return this.value?.id != null && this.value.id != 0;
    },
    name() {
      return this.value?.fullName ?? "";
    },
    id() {
      return this.value?.id ?? 0;
    },
    labelModified() {
      return `${this.label} - Type To Search`;
    },
  },
  mounted() {
    this.items = this.updateUsers([]);
  },
  methods: {
    debounceQuery: _.debounce(async function (val) {
      await this.queryGraph(val);
    }, 200),
    async queryGraph(val) {
      try {
        this.loading = true;

        const users = await QueryGraph(val);
        if (users) {
          this.items = this.updateUsers(users);
        }
      } catch (err) {
        globalEventHub.emitErrorEvent(
          "Error occured while searching for users", err, 'UserSelect'
        );
      } finally {
        this.loading = false;
      }
    },
    updateUsers(users) {
      if (this.internalValue) {
        users.push(this.internalValue);
      }
      if (this.allowUnassigned) {
        users = [{ fullName: "Unassigned", graphId: "0" }, ...users];
      }
      if (this.extraUsers != null && this.extraUsers.length > 0) {
        users = [...this.extraUsers, ...users];
      }
      return users;
    },
  },
  watch: {
    internalValue: {
      immediate: true,
      handler() {
        if (this.canEdit) {
          this.$emit("input", this.internalValue);
        }
      },
    },
    value: {
      immediate: true,
      handler() {
        this.internalValue = this.value;
        this.items = this.updateUsers([]);
      },
    },
    editing: {
      handler() {
        this.internalValue = this.value;
        this.items = this.updateUsers([]);
      },
    },
    search: {
      handler(val) {
        val && val !== this.select && this.debounceQuery(val);
      },
    },
  },
};
</script>
