// Reference: https://github.com/AzureAD/microsoft-authentication-library-for-js/tree/dev/samples/msal-browser-samples/vue3-sample-app
import { defineStore } from "pinia";
import {
  PublicClientApplication,
  LogLevel,
  InteractionStatus,
  EventType,
  EventMessageUtils,
} from "@azure/msal-browser";
import { loadRoles } from "@/dataAccess/userDataAccess";
import * as logger from "@/util/logger";

// Helper function to generate config given required values.
function msalConfig(clientId, authority, redirectUri) {
  return {
    auth: {
      clientId: clientId,
      authority: authority,
      redirectUri: redirectUri,
      postLogoutRedirectUri: "/",
    },
    cache: {
      cacheLocation: "localStorage", // This configures where your cache will be stored
      storeAuthStateInCookie: false, // Set this to "true" if you are having issues on IE11 or Edge
    },
    system: {
      tokenRenewalOffsetSeconds: 300,
      loggerOptions: {
        loggerCallback: (level, message, containsPii) => {
          if (containsPii) {
            logger.logInformation("Following logs contain PII", "loginStore.msalConfig");
          }
          switch (level) {
            case LogLevel.Error:
              logger.logError(message, "loginStore.msalConfig");
              return;
            case LogLevel.Info:
              // logger.logInformation(message, "loginStore.msalConfig");
              return;
            case LogLevel.Verbose:
              // logger.logDebug(message, "loginStore.msalConfig");
              return;
            case LogLevel.Warning:
              logger.logWarning(message, "loginStore.msalConfig");
              return;
          }
        },
      },
    },
  };
}

// Generic get a token silently, fall back to interactive if required
async function getToken(msal, request) {
  const tokenResp = await msal.acquireTokenSilent(request).catch(async (e) => {
    logger.logWarning(e, "loginStore.getToken");
    if (e.name == "InteractionRequiredAuthError") {
      const token = await msal.acquireTokenPopup(request);
      return token;
    }
    throw e;
  });
  return tokenResp;
}

/**
 * Helper function to determine whether 2 arrays are equal
 * Used to avoid unnecessary state updates
 * @param arrayA
 * @param arrayB
 */
function accountArraysAreEqual(arrayA, arrayB) {
  if (arrayA.length !== arrayB.length) {
    return false;
  }

  const comparisonArray = [...arrayB];

  return arrayA.every((elementA) => {
    const elementB = comparisonArray.shift();
    if (!elementA || !elementB) {
      return false;
    }

    return (
      elementA.homeAccountId === elementB.homeAccountId &&
      elementA.localAccountId === elementB.localAccountId &&
      elementA.username === elementB.username
    );
  });
}

// This store handles login, token acquisition, and user information.
export const useLoginStore = defineStore("loginStore", {
  state: () => {
    const clientId = "";
    const authority = "";
    const redirectUri = "";
    const internalMSAL = null;

    const inProgress = InteractionStatus.Startup;
    const accounts = [];

    const userRoles = [];

    const loginRequest = {
      scopes: ["User.Read", "User.ReadBasic.All", "Mail.Send"],
    };

    const userLookupRequest = {
      scopes: ["User.ReadBasic.All"],
    };

    const emailRequest = {
      scopes: ["Mail.Send"],
    }

    return {
      clientId,
      authority,
      redirectUri,
      internalMSAL,
      inProgress,
      accounts,
      loginRequest,
      userLookupRequest,
      userRoles,
      emailRequest,
    };
  },
  getters: {
    user: (state) => {
      if (state.inProgress === InteractionStatus.Startup) {
        state.internalMSAL.handleRedirectPromise().catch((err) => {
          logger.logError(err, "loginStore.getters.user");
          return;
        });
      }

      const rv = {};
      const acct = state?.internalMSAL?.getActiveAccount();

      rv.username = acct ? acct.username : null;
      rv.fullName = acct ? acct.name : null;
      rv.accountId = acct ? acct.localAccountId : null;

      rv.isAdmin = false;
      rv.isUser = false;
      if (acct) {
        rv.isAdmin = acct.idTokenClaims?.roles?.some(
          (r) => r == "ConnectMe.SystemAdministrator"
        );
        rv.isUser = acct.idTokenClaims?.roles?.some(
          (r) => r == "ConnectMe.User"
        );
      }

      if (!acct) {
        logger.logError("No active account", "loginStore.getters.user");
        logger.logInformation(`Returning RV: ${JSON.stringify(rv)}`);
      }
      return rv;
    },
    isAuthenticated: (state) => {
      const user = state.user;
      return !!user.accountId;
    },
    isAuthorized: (state) => {
      const user = state.user;
      return (user.isAdmin ?? false) || (user.isUser ?? false);
    },
    isAdmin: (state) => {
      const user = state?.user;
      return user?.isAdmin ?? false;
    },
    hasPermission: (state) => {
      return (role) => {
        return state?.userRoles?.some((r) => r.toLowerCase() === role.toLowerCase());
      }
    },
  },
  actions: {
    async init(clientId, authority, redirectUri) {
      this.clientId = clientId;
      this.authority = authority;
      this.redirectUri = redirectUri;

      this.internalMSAL = new PublicClientApplication(
        msalConfig(this.clientId, this.authority, this.redirectUri)
      );

      await this.internalMSAL.handleRedirectPromise();

      this.accounts = this.internalMSAL.getAllAccounts();
      if (this.accounts.length > 0) {
        this.internalMSAL.setActiveAccount(this.accounts[0]);
      }

      this.internalMSAL.addEventCallback((message) => {
        if (message.eventType === EventType.LOGIN_SUCCESS && message.payload) {
          this.internalMSAL.setActiveAccount(message.payload.account);
        }

        switch (message.eventType) {
          case EventType.ACCOUNT_ADDED:
          case EventType.ACCOUNT_REMOVED:
          case EventType.LOGIN_SUCCESS:
          case EventType.SSO_SILENT_SUCCESS:
          case EventType.HANDLE_REDIRECT_END:
          case EventType.LOGIN_FAILURE:
          case EventType.SSO_SILENT_FAILURE:
          case EventType.LOGOUT_END:
          case EventType.ACQUIRE_TOKEN_SUCCESS:
          case EventType.ACQUIRE_TOKEN_FAILURE:
            {
              const currentAccounts = this.internalMSAL.getAllAccounts();
              if (!accountArraysAreEqual(currentAccounts, this.accounts)) {
                this.accounts = currentAccounts;
                this.internalMSAL.setActiveAccount(currentAccounts[0]);
              }
            }
            break;
        }

        const status = EventMessageUtils.getInteractionStatusFromEvent(
          message,
          this.inProgress
        );
        if (status !== null) {
          this.inProgress = status;
        }
      });
    },
    async login() {
      await this.internalMSAL.handleRedirectPromise();
      if (this.internalMSAL?.getActiveAccount() !== null) {
        return;
      }

      this.internalMSAL.loginRedirect(this.loginRequest);
    },
    async logout() {
      await this.internalMSAL.handleRedirectPromise();
      this.accounts = [];
      this.internalMSAL.logoutRedirect();
    },
    async getServerToken() {
      const serverTokenRequest = {
        scopes: [`api://${this.clientId}/Access`],
      };
      await this.internalMSAL.handleRedirectPromise();
      const tokenResp = await getToken(this.internalMSAL, serverTokenRequest);
      return tokenResp.accessToken
    },
    async loadUserRoles() {
      try {
        const roleResp = await loadRoles();
        if (roleResp.errors) {
          return roleResp.errors;
        }

        this.userRoles = roleResp.data;

        return null;
      } catch (err) {
        logger.logError(err, "loginStore");
        return "Unable to load user roles.";
      }
    },
    async getEmailToken() {
      await this.internalMSAL.handleRedirectPromise();

      const emailTokenResp = await getToken(
        this.internalMSAL,
        this.emailRequest
      );

      return emailTokenResp.accessToken;
    },
    async getGraphToken() {
      await this.internalMSAL.handleRedirectPromise();
      const graphTokenResp = await getToken(
        this.internalMSAL,
        this.userLookupRequest
      );

      return graphTokenResp.accessToken
    },
  },
});
