<template>
  <v-checkbox
    v-model="internalValue"
    @click="emitClick"
    class="ma-0"
    :rules="rules"
    :disabled="!isEditing"
    :hide-details="hideDetails"
    v-bind="$attrs"
  >
    <template slot="label">
      <span class="d-none"> {{ label }} </span>
    </template>
  </v-checkbox>
</template>

<script>
// this component provides accessable checkboxes with labels that fit in our design scheme
export default {
  name: "ToggleCheckbox",
  components: {},
  props: {
    isEditing: {
      type: Boolean,
      default: false,
    },
    value: {
      type: Boolean,
      default: false,
    },
    rules: {
      type: Array,
      default: () => [],
    },
    label: {
      type: String,
      required: true,
    },
    hideDetails: {
      type: [Boolean, String],
      default: false,
    },
  },
  data: () => ({
    internalValue: false,
  }),
  methods: {
    emitClick() {
      this.$emit("click");
    },
  },
  computed: {},
  watch: {
    internalValue: {
      handler() {
        this.$emit("input", this.internalValue);
      },
    },
    value: {
      immediate: true,
      handler() {
        this.internalValue = this.value;
      },
    },
    isEditing: {
      handler() {
        this.internalValue = this.value;
      },
    },
  },
};
</script>

<style lang="scss" scoped>
::v-deep .v-input__control {
  flex-grow: 0;
  width: unset;
}
</style>