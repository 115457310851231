function formatFacilityNameForSearch(facilities) {
  //need to avoid running map on facilities, it will kill performance
  //so building a new object list seems best, think this is the smallest element list we can use
  let returnList = [];
  facilities.forEach((f) => {
    returnList.push({
      displayName: f.frameworkId ? `(${f.frameworkId}) ${f.name}` : f.name,
      name: f.name,
      id: f.id,
      parentCompany: f.parentCompany,
      parentCompanyId: f.parentCompanyId,
      frameworkId: f.frameworkId,
    });
  });
  return returnList;
}
export { formatFacilityNameForSearch };
