<template>
  <div>
    <div v-if="!isEditing || !canEdit" :class="viewClassList">
      <div v-if="isAssigned" class="d-flex">
        <v-row no-gutters>
          <div v-for="user in value" :key="user.graphId" class="pr-2">
            <router-link
              v-if="user.id"
              :to="{
                name: 'User',
                params: { id: `${user.id}` },
              }"
            >
              {{ user.fullName }}
            </router-link>
            <div v-else>
              {{ user.fullName }}
            </div>
          </div>
        </v-row>
      </div>
      <div v-else>{{ emptyText }}</div>
    </div>
    <v-autocomplete
      v-if="isEditing && canEdit"
      :placeholder="labelModified"
      prepend-inner-icon="mdi-magnify"
      v-model="internalValue"
      :search-input.sync="search"
      :loading="loading"
      :items="items"
      hide-no-data
      outlined
      dense
      item-text="fullName"
      item-value="graphId"
      :filter="filter"
      return-object
      clearable
      :rules="rules"
      :hide-details="hideDetails"
      chips
      deletable-chips
      multiple
      cache-items
      @change="clearSearch"
    >
      <template v-slot:item="data">
        <template>
          <v-list-item-content>
            <v-list-item-title>{{ data.item.fullName }}</v-list-item-title>
            <v-list-item-subtitle class="text--disabled">
              {{ data.item.emailAddress }}
            </v-list-item-subtitle>
          </v-list-item-content>
        </template>
      </template>
    </v-autocomplete>
  </div>
</template>

<script>
import _ from "lodash";
import { globalEventHub } from "@/util/globalEvents";
import { QueryGraph } from "@/logic/UserLookupLogic";

export default {
  name: "UserMultiSelect",
  props: {
    canEdit: {
      type: Boolean,
      default: true,
    },
    isEditing: {
      type: Boolean,
      default: false,
    },
    viewClassList: {
      type: String,
      default: "",
    },
    value: {
      type: Array,
      default: () => {},
    },
    label: {
      type: String,
      default: "User",
    },
    rules: {
      type: Array,
      default: () => [],
    },
    filter: {
      type: Function,
      default: (item, queryText, itemText) => {
        if (itemText === "Unassigned") return true;
        var filtered =
          itemText.toLocaleLowerCase().indexOf(queryText.toLocaleLowerCase()) >
          -1;
        return filtered;
      },
    },
    emptyText: {
      type: String,
      default: "",
    },
    hideDetails: {
      type: [Boolean, String],
      default: false,
    },
  },
  data: () => ({
    internalValue: [],
    search: null,
    loading: false,
    items: [],
  }),
  computed: {
    isAssigned() {
      return this.value?.length > 0;
    },
    labelModified() {
      return `${this.label} - Type To Search`;
    },
  },
  mounted() {
    this.items = this.updateUsers([]);
  },
  methods: {
    debounceQuery: _.debounce(async function (val) {
      await this.queryGraph(val);
    }, 200),
    async queryGraph(val) {
      try {
        this.loading = true;

        const users = await QueryGraph(val);
        if (users) {
          this.items = this.updateUsers(users);
        }
      } catch (err) {
        globalEventHub.emitErrorEvent(
          "Error occured while searching for users", err, 'UserMultiSelect'
        );
      } finally {
        this.loading = false;
      }
    },
    updateUsers(users) {
      if (this.internalValue.length > 0) {
        this.internalValue.forEach((u) => {
          users.push(u);
        });
      }
      return users;
    },
    clearSearch() {
      this.search = null;
    },
  },
  watch: {
    internalValue: {
      immediate: true,
      handler() {
        if (this.canEdit) {
          this.$emit("input", this.internalValue);
        }
      },
    },
    value: {
      immediate: true,
      handler() {
        this.internalValue = this.value;
        this.items = this.updateUsers([]);
      },
    },
    isEditing: {
      handler() {
        this.internalValue = this.value;
        this.items = this.updateUsers([]);
      },
    },
    search: {
      handler(val) {
        val && val !== this.select && this.debounceQuery(val);
      },
    },
  },
};
</script>

<style scoped>
/* Workaround for placeholder text not showing with chips */
/* https://github.com/vuetifyjs/vuetify/issues/11553 */
:deep(.v-autocomplete:not(.v-input--is-focused).v-select--chips input) {
  max-height: 25px !important;
}
</style>