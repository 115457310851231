let appInsights = null;
let logConsole = false;

function setAppInsights(ai) {
    appInsights = ai;
}

function logTrace(message, objectName) {
    generalLog("Trace", message, objectName, "color: #888888");
}

function logInformation(message, objectName) {
    generalLog("Information", message, objectName, "color: #4499dd");
}

function logWarning(message, objectName) {
    generalLog("Warning", message, objectName, "color: #FF8800");
}

function logError(message, objectName) {
    generalLog("Error", message, objectName, "color: #FF0000");
}

function logCritical(message, objectName) {
    generalLog("Critical", message, objectName, "color: #FF00FF");
}

function mapToAILevel(level) {
    switch (level) {
        case "Trace":
            return 0;
        case "Information":
            return 1;
        case "Warning":
            return 2;
        case "Error":
            return 3;
        case "Critical":
            return 4;
    }
}


function generalLog(level, message, objectName, css) {
    let shouldLogConsole = logConsole;
    if (!css) {
        css = "color: #000000";
    }

    if (!(typeof message === 'string' || message instanceof String))
    {
        message = JSON.stringify(message);
    }

    if (appInsights !== null) {
        appInsights.trackTrace({
            message: `${objectName}: ${message}`,
            SeverityLevel: mapToAILevel(level),
            properties: {}
        });
    }
    else {
        shouldLogConsole = true;
    }

    // Pick up environment variable development. If set, turn on console logging. 
    if (!shouldLogConsole) {
        logConsole = (process.env.NODE_ENV == "development")
        shouldLogConsole = logConsole;
    }

    if (shouldLogConsole) {  // Console logger is default fallback
        if (objectName) {
            console.log(`%c ${level}\\${objectName}: ${message}`, css);
        } else {
            console.log(`%c ${level}: ${message}`, css);
        }
    }
}


export { logTrace, logInformation, logWarning, logError, logCritical, setAppInsights };
