<template>
  <v-chip
    v-if="hasValue"
    :class="pillClass"
    pill
    :color="color"
    :text-color="textColor"
  >
    {{ status }}
  </v-chip>
</template>

<script>
// todo: switch to using vuetify colors instead of the hard-coded values... please
export default {
  name: "StatusPill",
  props: {
    status: {
      type: String,
      default: "",
    },
    addClass: {
      type: String,
      default: "",
    },
  },
  computed: {
    pillClass() {
      return "ms-3 font-weight-bold " + this.addClass;
    },
    hasValue() {
      return this.status && this.status.length > 0;
    },
    color() {
      const lowerStatus = this.status.toLowerCase();
      switch (lowerStatus) {
        case "active":
          return "#FFFAE6";
        case "new":
          return "lightSecondary";
        case "closed":
        case "inactive":
          return "lightGrey";
        default:
          return "";
      }
    },
    textColor() {
      const lowerStatus = this.status.toLowerCase();
      switch (lowerStatus) {
        case "active":
          return "burntOrange";
        case "new":
          return "secondary";
        case "closed":
        case "inactive":
          return "darkGrey";
        default:
          return "";
      }
    },
  },
};
</script>
