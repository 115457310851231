<template>
  <v-container fluid>
    <v-card
      height="100%"
      :class="{
        'ma-15': $vuetify.breakpoint.mdAndUp,
        'ma-2': $vuetify.breakpoint.smAndDown,
      }"
    >
      <v-card-title>
        <h1 class="text-h5 pa-10">Loading...</h1>
      </v-card-title>
    </v-card>
  </v-container>
</template>

<script>
export default {
  name: "LoadingScreen",
};
</script>
