<template>
  <v-container
    fluid
    :style="{ background: $vuetify.theme.themes[theme].background }"
  >
    <v-row class="px-4">
      <v-col cols="12">
        <div v-if="backButton" class="mb-3 back-container">
          <a class="link-back" @click="goBackInHistory">
            <div class="d-flex align-center">
              <v-icon color="primary">mdi-chevron-left</v-icon>
              <span class="ml-2">BACK</span>
            </div>
          </a>
        </div>
        <slot v-if="!displayNotFound"></slot>
        <not-found v-else></not-found>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import * as lastRoute from "@/util/lastRoute";
import * as logger from "@/util/logger"
import NotFound from "@/components/NotFound.vue";

export default {
  name: "PageLayout",
  props: {
    backButton: {
      type: Boolean,
      default: true,
    },
    displayNotFound: {
      type: Boolean,
      default: false,
    },
  },
  components: {
    NotFound,
  },
  data: () => ({}),
  methods: {
    async goBackInHistory() {
      try {
        const lastPath = lastRoute.getLastPathAndPop();
        if (lastPath == "/") {
          this.$router.push({ name: "MyTickets" });
        } else {
          lastRoute.setInternalBack();
          this.$router.go(-1);
        }
      } catch (e) {
        logger.logError(e, 'PageLayout');
        this.$router.go(-1);
      }
    },
  },
  computed: {
    theme() {
      return this.$vuetify.theme.dark ? "dark" : "light";
    },
  },
};
</script>

<style scoped>
.link-back {
  text-decoration: none;
}
.back-container {
  max-width: 200px;
}
</style>
