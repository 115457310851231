import axiosClient from "./axiosClient";
import { useLoginStore } from "@/store/loginStore";
import { logError } from "@/util/logger";
import { isUpToDate } from "@/util/serviceWorkerState";
import { globalEventHub } from "@/util/globalEvents";

const notUpdatedMessage = "Please refresh your browser to get the latest version before saving data.";

function getNotUpdatedError() {
  return {
    code: 409,
    errors: [{ message: notUpdatedMessage }],
  };
}

class apiWrapper {
  async getAuthenticated(actionRoute, queryParams, responseType) {
    await this.appendDefaultRequestHeaders();
    try {
      let apiResponse = await axiosClient.get(actionRoute, {
        params: queryParams,
        responseType,
      });
      return {
        data: apiResponse.data,
      };
    } catch (err) {
      return this.handleApiCallError(err);
    }
  }

  async postAuthenticated(actionRoute, data, queryParams) {
    if (!isUpToDate()) {
      globalEventHub.emitErrorEvent(notUpdatedMessage);
      return getNotUpdatedError();
    }

    await this.appendDefaultRequestHeaders();
    try {
      let apiResponse = await axiosClient.post(actionRoute, data, {
        params: queryParams,
      });

      let apiWarning = undefined;
      if (
        Array.isArray(apiResponse.data) &&
        apiResponse.data.some((err) => err.field === "ErrorWarning")
      ) {
        apiWarning = apiResponse.data.find(
          (err) => err.field === "ErrorWarning"
        ).message;
      }

      return {
        data: apiResponse.data,
        warning: apiWarning,
      };
    } catch (err) {
      return this.handleApiCallError(err);
    }
  }

  async putAuthenticated(actionRoute, data, queryParams) {
    if (!isUpToDate()) {
      globalEventHub.emitErrorEvent(notUpdatedMessage);
      return getNotUpdatedError();
    }

    await this.appendDefaultRequestHeaders();
    try {
      let apiResponse = await axiosClient.put(actionRoute, data, {
        params: queryParams,
      });

      return {
        data: apiResponse.data,
      };
    } catch (err) {
      return this.handleApiCallError(err);
    }
  }

  async patchAuthenticated(actionRoute, data, queryParams) {
    if (!isUpToDate()) {
      globalEventHub.emitErrorEvent(notUpdatedMessage);
      return getNotUpdatedError();
    }

    await this.appendDefaultRequestHeaders();
    try {
      let apiResponse = await axiosClient.patch(actionRoute, data, {
        params: queryParams,
      });

      return {
        data: apiResponse.data,
      };
    } catch (err) {
      return this.handleApiCallError(err);
    }
  }

  async deleteAuthenticated(actionRoute, data, queryParams) {
    if (!isUpToDate()) {
      globalEventHub.emitErrorEvent(notUpdatedMessage);
      return getNotUpdatedError();
    }

    await this.appendDefaultRequestHeaders();
    try {
      let apiResponse = await axiosClient.delete(actionRoute, {
        data: data,
        params: queryParams,
      });

      return {
        data: apiResponse.data,
      };
    } catch (err) {
      return this.handleApiCallError(err);
    }
  }

  async getAnonymous(actionRoute, queryParams) {
    try {
      let apiResponse = await axiosClient.get(actionRoute, {
        params: queryParams,
      });
      return {
        data: apiResponse.data,
      };
    } catch (err) {
      return this.handleApiCallError(err);
    }
  }

  async navigateToRoute(actionRoute) {
    window.location.href = `${axiosClient.defaults.baseURL}/${actionRoute}`;
  }

  async appendDefaultRequestHeaders() {
    const loginStore = useLoginStore();
    const token = await loginStore.getServerToken();
    axiosClient.defaults.headers.common["Authorization"] = `Bearer ${token}`;

    axiosClient.defaults.headers.common["Timezone-Offset"] =
      new Date().getTimezoneOffset();
  }

  handleApiCallError(err) {
    var errorInfo = {
      code: err.response?.status,
      errors: err.response?.data,
    };
    try {
      logError(err, "apiWrapper");
      if (err.response?.data) {
        errorInfo.errors = err.response.data;
      } else if (err.request.response) {
        //Sometimes axios doesn't want to parse the body - so fall back to diy
        errorInfo.errors = JSON.parse(err.request.response);
      } else if (err.response && !err.response.data) {
        if (err.response.status === 403) {
          errorInfo.errors = [
            { message: "You are not authorized to do this." },
          ];
        } else if (err.response.status === 401) {
          errorInfo.errors = [
            { message: "Your session has expired. Please refresh." },
          ];
        }
      }
    } finally {
      if (!errorInfo.errors) {
        errorInfo.errors = [{ message: "An unknown error occurred." }];
      }
    }

    return errorInfo;
  }
}

export default apiWrapper;
