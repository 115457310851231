import axios from "axios";

const baseUrl = process.env.VUE_APP_API_URL
  ? process.env.VUE_APP_API_URL
  : window.location.origin;

var axiosClient = axios.create({
  baseURL: baseUrl,
  withCredentials: true,
});

export default axiosClient;
