<template>
  <v-card class="ma-15">
    <v-card-title>
      <h1 class="text-h5">ConnectMe</h1>
    </v-card-title>
    <v-card-text>
      <p class="red--text">Something went wrong.</p>
      <p class="red--text">{{ errorText }}</p>
    </v-card-text>
  </v-card>
</template>

<script>
export default {
  name: "ErrorDisplay",
  props: {
    errorText: {
      type: String,
      required: true,
    },
  },
};
</script>
