import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import vuetify from "./plugins/vuetify";
import { cookies } from "./plugins/cookies";
import "./registerServiceWorker";
import { formatDate } from "@/util/dateFormats";
import { createPinia, PiniaVuePlugin } from "pinia";
import VueTelInputVuetify from 'vue-tel-input-vuetify/lib';

// Import common components
import DataRow from "@/components/DataRow.vue";
import NoteRow from "@/components/NoteRow.vue";
import FieldSelect from "@/components/FieldControls/FieldSelect.vue";
import FieldTextArea from "@/components/FieldControls/FieldTextArea.vue";
import PageLayout from "@/components/page/PageLayout.vue";
import FacilitySelect from "@/components/FieldControls/FacilitySelect.vue";
import UserSelect from "@/components/FieldControls/UserSelect";
import ToggleCheckbox from "@/components/FieldControls/ToggleCheckbox.vue";
import LabelCheckbox from "@/components/FieldControls/LabelCheckbox";
import FieldSectionTitle from "@/components/FieldControls/FieldSectionTitle";
import FieldText from "@/components/FieldControls/FieldText";
import TimeSelect from "@/components/FieldControls/TimeSelect";
import StatusPill from "@/components/FieldControls/StatusPill";
import UserMultiSelect from '@/components/FieldControls/UserMultiSelect.vue';
import FacilityMultiSelect from '@/components/FieldControls/FacilityMultiSelect.vue';

Vue.component("data-row", DataRow);
Vue.component("note-row", NoteRow);
Vue.component("field-select", FieldSelect);
Vue.component("field-text-area", FieldTextArea);
Vue.component("page-layout", PageLayout);
Vue.component("facility-select", FacilitySelect);
Vue.component("user-select", UserSelect);
Vue.component("toggle-checkbox", ToggleCheckbox);
Vue.component("label-checkbox", LabelCheckbox);
Vue.component("field-section-title", FieldSectionTitle);
Vue.component("field-text", FieldText);
Vue.component("time-select", TimeSelect);
Vue.component("status-pill", StatusPill);
Vue.component("user-multi-select", UserMultiSelect);
Vue.component("facility-multi-select", FacilityMultiSelect);

Vue.config.productionTip = false;

var cookiesInstance = new cookies();
Vue.use(cookiesInstance);

Vue.use(PiniaVuePlugin);
const pinia = createPinia();

Vue.use(VueTelInputVuetify, {
  vuetify
});

Vue.filter("formatDate", (date) => {
  if (!date) {
    return "";
  }
  if (date instanceof Date) {
    return formatDate(date);
  }

  return formatDate(new Date(date));
});

new Vue({
  router,
  vuetify,
  pinia,
  render: (h) => h(App),
}).$mount("#app");
