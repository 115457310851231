<template>
  <v-container fluid class="h-100">
    <v-row class="d-flex justify-center flex-column align-center h-100">
      <div class="rotate-container">
        <div :class="logoClass">
          <v-img src="@/assets/consonus_logo.svg"></v-img>
        </div>
      </div>
      <div class="text-h6 text-center pt-3" v-if="hasError">
        <div>{{ errorText }}</div>
        <v-btn @click="signOut" outlined class="mt-2">Sign Out</v-btn>
      </div>
      <div class="text-h5 text-center pt-3" v-else>Loading ConnectMe...</div>
    </v-row>
  </v-container>
</template>

<script>
import { useLoginStore } from "@/store/loginStore";

export default {
  name: "SplashPage",
  components: {},
  props: {
    errorText: {
      type: String,
      default: "",
    },
  },
  data: () => ({}),
  computed: {
    hasError() {
      return this.errorText.length > 0;
    },
    logoClass() {
      if (!this.hasError) {
        return "rotate logo";
      }
      return "logo";
    },
  },
  methods: {
    async signOut() {
      const ls = useLoginStore();
      await ls.logout();
    },
  },
};
</script>

<style scoped>
.rotate-container {
  perspective: 1000px;
}

.logo {
  width: 200px;
  height: 200px;
}

.rotate {
  transform: rotateY(45deg);
  animation: rotateAnimation 1.5s linear infinite;
}

@keyframes rotateAnimation {
  from {
    transform: rotateY(45deg);
  }
  to {
    transform: rotateY(225deg);
  }
}
</style>