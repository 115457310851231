<template>
  <div>
    <v-snackbar
      v-for="success in successes"
      :key="success.index"
      v-model="successes[success.index].isShown"
      :timeout="2000"
      :multi-line="true"
      color="success"
    >
      <span class="black--text font-weight-medium" bold>{{
        success.message
      }}</span>
      <template v-slot:action="{ attrs }">
        <v-btn
          color="black"
          text
          v-bind="attrs"
          @click="successes[success.index].isShown = false"
        >
          Close
        </v-btn>
      </template>
    </v-snackbar>
  </div>
</template>

<script>
import { globalEventHub, globalEvents } from "@/util/globalEvents";

export default {
  name: "GlobalSuccessSnackbar",
  data: () => ({
    successes: [],
  }),
  created: function () {
    globalEventHub.subscribeToGlobalEvent(
      globalEvents.handleSuccess,
      (message) => {
        this.showSuccessMessage(message);
      }
    );
  },
  methods: {
    showSuccessMessage(successMessage) {
      this.successes.push({
        message: successMessage,
        index: this.successes.length,
        isShown: true,
      });
    },
  },
};
</script>
