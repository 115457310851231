<template>
  <v-row no-gutters>
    <v-col cols="12" class="font-italic">
      <slot></slot>
    </v-col>
  </v-row>
</template>

<script>
export default {
  name: "NoteRow",
  components: {},
  data: () => ({}),
  computed: {},
  methods: {},
};
</script>