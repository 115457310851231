<template>
  <div class="d-flex align-center">
    <toggle-checkbox
      v-if="isEditing"
      v-model="internalValue"
      :isEditing="isEditing"
      :label="label"
      :rules="rules"
    >
    </toggle-checkbox>
    <div :class="isEditing ? 'pb-4' : ''">{{ activeLabel }}</div>
  </div>
</template>

<script>
// this component provides accessable checkboxes with labels that fit in our design scheme
export default {
  name: "LabelCheckbox",
  components: {},
  props: {
    isEditing: {
      type: Boolean,
      default: false,
    },
    value: {
      type: Boolean,
      default: false,
    },
    rules: {
      type: Array,
      default: () => [],
    },
    label: {
      type: String,
      required: true,
    },
  },
  data: () => ({
    internalValue: false,
  }),
  methods: {},
  computed: {
    activeLabel() {
      return this.value ? "Active" : "Inactive";
    },
  },
  watch: {
    internalValue: {
      immediate: true,
      handler() {
        this.$emit("input", this.internalValue);
      },
    },
    value: {
      immediate: true,
      handler() {
        this.internalValue = this.value;
      },
    },
    isEditing: {
      handler() {
        this.internalValue = this.value;
      },
    },
  },
};
</script>
