import * as lcache from "@/util/localCache";
import * as logger from "@/util/logger";

// Sets a value from cache if it exists, otherwise loads it from the server
// Takes callbacks for the value setter and the load function.
// returns null on success, or the error object on failure.
// cacheKey is the cache key
// setValue is a callback to set the value in the store
// getWait is a callback to get if we need to wait or not
// setWait is a method to set the wait value from this method
// loadAsync is the async function to load the value from the server
async function setOrLoadWithWaitAsync(cacheKey,
    setValue,
    getWait,
    setWait,
    loadAsync) {

    // first check the cache
    const cached = lcache.Get(cacheKey);
    if (cached) {
        setValue(cached);
        return null;
    }

    // sleep wait up to 5 seconds
    let sleepCounter = 0;
    if (getWait()) {
        do {
            await new Promise((resolve) => setTimeout(resolve, 250));
            sleepCounter++;

            // check the cache again
            const cached = lcache.Get(cacheKey);
            if (cached) {
                setValue(cached);
                return null;
            }
        } while (getWait() && sleepCounter < 20);
        if (getWait()) {
            // log warning if we timed out, but don't return an error
            logger.logWarning(`Reference data load timeout - cache key ${cacheKey}`, "storeUtils.setOrLoadWithWaitAsync")
        }
    }

    // Trigger wait, and load the value from the server, set the value and cache it
    setWait(true);
    const result = await loadAsync();
    if (result.errors) {
        return result.errors;
    }
    setValue(result.data);

    lcache.Add(cacheKey, result.data);
    setWait(false);

    return null;
}

export { setOrLoadWithWaitAsync };