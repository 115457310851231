import { defineStore } from "pinia";
import { getSettings } from "@/dataAccess/clientAppSettingsDataAccess";

// This store handles settings for the application
export const useSettingsStore = defineStore("settingsStore", {
  state: () => {
    const _appVersion = null;
    const _clientId = null;
    const _tenantId = null;
    const _aiKey = null;
    const _environmentMessage = null;

    return {
      _appVersion,
      _clientId,
      _tenantId,
      _aiKey,
      _environmentMessage,
    };
  },
  getters: {
    appVersion: (state) => state._appVersion,
    clientId: (state) => state._clientId,
    tenantId: (state) => state._tenantId,
    appInsightsKey: (state) => state._aiKey,
    environmentMessage: (state) => state._environmentMessage,
  },
  actions: {
    async loadSettings() {
      const result = await getSettings();
      if (result.errors) {
        return result.errors;
      }

      this._appVersion = result.data.appVersion;
      this._clientId = result.data.clientId;
      this._tenantId = result.data.tenantId;
      this._aiKey = result.data.appInsightsKey;
      this._environmentMessage = result.data.environmentMessage;

      return null;
    },
  },
});
